import * as React from "react";
import { Image, ImageProps } from "@chakra-ui/react";
import { getFileType } from "@karkhanaui/react";
import {
  FaFilePdf,
  FaFileExcel,
  FaFileWord,
  FaFileImage,
  FaFilePowerpoint,
} from "react-icons/fa";
import { BsGear } from "react-icons/bs";

export interface thumbailProps {
  fileUrl: string;
  isPrivateFile?: boolean;
  alternativeImg?: ImageProps;
}

export const ThumbnailFile = ({
  fileUrl,
  isPrivateFile,
  alternativeImg,
}: thumbailProps) => {
  switch (getFileType(fileUrl)) {
    case "image":
      return <FaFileImage  size="30px" />;
    case "pdf":
      return <FaFilePdf  size="30px" color="#F15642" />;
    case "spreadSheet":
      return <FaFileExcel  size="30px" color="#F15642"/>;
    case "word":
      return <FaFileWord  size="30px" color="#2b579a"  />;
    case "CAD_File":
      return <BsGear  size="30px" />;
    case "presentation":
      return <FaFilePowerpoint  size="30px" color="#d24726" />;
    default:
      return <BsGear  size="30px" />;
  }
};
