import React, { useState, useEffect, useRef } from "react";
import { Box, Text, Flex, Textarea, Image, Divider } from "@chakra-ui/react";
import Cookies from "js-cookie";
import { FiPaperclip } from "react-icons/fi";
import {
	FilesSlider,
	ThemeButton,
	File,
	normalDate,
	type AppDispatch,
	setPageLoading,
	SET_MESSAGE,
	ERROR_MSG,
	formatErrorMessage,
	frappeMethodService,
	Thumbnail,
	correctImageOrientation,
} from "@karkhanaui/react";
import { useDropzone } from "react-dropzone";
import { useDispatch } from "react-redux";
import NoUpdates from "../../img/NoUpdates.png";
import axios from "axios";
import { SliderFile } from "./SliderFile";

const Chat = (props: any) => {
	const dispatch = useDispatch<AppDispatch>();
	const supplierName = Cookies.get("full_name") || "S";
	const [message, setMessage] = useState<string>("");
	const [customerName, setCustomerName] = useState<string>("C");
	const [allMessages, setAllMessages] = useState<any>([]);
	const [selectedFiles, setSelectedFiles] = useState<any>([]);
	const [sendButtonLoading, setSendButtonLoading] = useState<boolean>(false);
	const [chatToken, setChatToken] = React.useState<string>("");
	const [chatRoom, setChatRoom] = React.useState<any>();
	const loggedInUser = Cookies.get("user_id");
	const lastChatRef = useRef(null);

	const errorHandler = (error: any) => {
		dispatch(
			setPageLoading({
				loading: false,
				loadingText: "",
			})
		);
		dispatch({
			type: SET_MESSAGE,
			payload: {
				case: ERROR_MSG,
				data: formatErrorMessage(error),
			},
		});
	};

	const onDrop = React.useCallback(
		async (acceptedFiles: any) => {
			setSendButtonLoading(true);
			const files = await acceptedFiles?.map(async (item: any) => {
				if (item.type === "image/jpeg") {
					item = await correctImageOrientation(item).catch((error: any) => {
						console.error(
							"Error correcting orientation for:",
							item.name,
							error
						);
						return item; // Use the original file if correction fails
					});
				}
				return item;
			});

			const uploadedFiles = (await Promise.all(files)).filter(Boolean);
			setSelectedFiles([...selectedFiles, ...uploadedFiles]);
			setSendButtonLoading(false);
		},
		[selectedFiles]
	);
	const {
		getRootProps,
		getInputProps,
		open,
		isDragActive,
		isDragAccept,
		isDragReject,
	} = useDropzone({
		onDrop,
		// Disable click and keydown behavior
		noClick: true,
		noKeyboard: true,
		multiple: true,
	});

	const deleteSelectedFile = (file: any) => {
		const updatedFiles = selectedFiles.filter((f: any) => f !== file);
		setSelectedFiles(updatedFiles);
	};

	function formatDateTime(inputString: string) {
		const months = [
			"Jan",
			"Feb",
			"Mar",
			"Apr",
			"May",
			"Jun",
			"Jul",
			"Aug",
			"Sep",
			"Oct",
			"Nov",
			"Dec",
		];

		const dateParts = inputString.split(" ")[0].split("-");
		const timeParts = inputString.split(" ")[1].split(":");

		const year = dateParts[0];
		const month = months[parseInt(dateParts[1]) - 1];
		const day = dateParts[2];
		let hour = parseInt(timeParts[0]);
		const minute = timeParts[1];

		// Format hour and determine AM/PM
		let ampm = "AM";
		if (hour >= 12) {
			ampm = "PM";
		}
		if (hour > 12) {
			hour -= 12;
		}

		// Pad single-digit hour with a leading zero
		const formattedHour = hour.toString().padStart(2, "0");

		const formattedDateTime = `${formattedHour}:${minute} ${ampm} ${month} ${day}`;
		return formattedDateTime;
	}

	function removeSuffix(email: string, suffix: string) {
		if (email.endsWith(suffix)) {
			return email.slice(0, email.lastIndexOf(suffix));
		}

		return email;
	}

	const transformChat = async (chats: any) => {
		let _chats: any = [];
		await chats?.map((chat: any) => {
			_chats.push({
				time: formatDateTime(chat.creation),
				content: chat.message,
				isSupplier: removeSuffix(chat?.sender, ".supplier") === loggedInUser,
				files: chat?.files,
				sender: removeSuffix(chat?.sender, ".procurement"),
			});
		});
		return _chats;
	};

	const getChats = () => {
		axios
			.get(
				`${process.env.REACT_APP_CHAT_SERVER}/api/method/karkhana_chat.api.get_chat_messages`,
				{
					headers: { Authorization: chatToken },
					params: {
						chat_room: chatRoom?.name,
					},
				}
			)
			.then(async (res: any) => {
				const _prevChats = await transformChat(res.data?.message);
				setAllMessages([..._prevChats]);
			})
			.catch((error: any) => errorHandler(error));
	};

	const sendMessage = async () => {
		setSendButtonLoading(true);
		let formData = new FormData();
		selectedFiles?.map((item: any, index: number) => {
			formData.append(`file_${index + 1}`, item);
		});
		formData.append("message", message);
		formData.append("chat_room", chatRoom?.name);

		try {
			const response = await axios.post(
				`${process.env.REACT_APP_CHAT_SERVER}/api/method/karkhana_chat.api.add_chat`,
				formData,
				{
					headers: {
						Authorization: chatToken,
					},
				}
			);
			setMessage("");
			setSelectedFiles([]);
			getChats();
			setSendButtonLoading(false);
		} catch (error) {
			setSendButtonLoading(false);
			errorHandler(error);
		}
	};

	const fetchInitialData = async () => {
		let so_data: any;
		let chatroom: any;
		try {
			await axios
				.get(
					process.env.REACT_APP_AUTH_LIVE_URL +
						`/api/resource/Sales%20Order/${props?.salesOrderId}`
				)
				.then((res: any) => {
					so_data = res.data.data;
				});

			//get the chat token
			const token = await frappeMethodService(
				"karkhana_chat_sdk.api.get_user_token",
				{
					platform: "supplier",
				}
			);
			const chatToken = token.data?.message?.token;
			setChatToken(chatToken);

			//check whether chat room exists or not
			const chatRoomRequest = await axios.get(
				process.env.REACT_APP_CHAT_SERVER + "/api/resource/Chat Room",
				{
					headers: { Authorization: chatToken },
					params: {
						fields: JSON.stringify(["*"]),
						filters: JSON.stringify([
							["context_type", "=", "PO"],
							["context_reference", "=", so_data?.po_uuid],
							["context_description", "=", so_data?.po_no],
						]),
					},
				}
			);
			chatroom = chatRoomRequest.data.data[0];
			setChatRoom(chatRoomRequest.data.data[0]);
		} catch (error) {
			errorHandler(error);
		}
	};

	useEffect(() => {
		setCustomerName(props?.customerName);
		fetchInitialData();
	}, [props?.salesOrderId]);

	useEffect(() => {
		if (chatRoom?.name) {
			getChats();
		}
	}, [chatRoom]);

	useEffect(() => {
		if (lastChatRef.current) {
			lastChatRef.current.scrollIntoView({
				behavior: "smooth",
				block: "start",
			});
		}
	}, [allMessages]);

	useEffect(() => {
		let interval: any;
		if (chatRoom?.name) {
			interval = setInterval(() => {
				getChats();
			}, 1500);
		}
		return () => clearInterval(interval);
	}, [chatRoom]);

	return (
		<Box height="100%">
			{allMessages?.length > 0 ? (
				<Box
					height="calc(100vh - 31rem)"
					overflowY="auto"
					pb=".5rem"
					scrollPaddingBottom="1rem"
				>
					{allMessages?.map((message: any, index: number) => (
						<Flex
							alignItems="flex-start"
							justifyContent={message?.isSupplier ? "flex-end" : "flex-start"}
							gap=".5rem"
							mt="1.25rem"
							key={index}
							ref={index === allMessages?.length - 1 ? lastChatRef : null}
						>
							<Flex
								order={message?.isSupplier ? 2 : 1}
								alignItems="center"
								justifyContent="center"
								height="2rem"
								width="2rem"
								bg={
									message?.isSupplier
										? "rgba(75, 161, 138, 0.05)"
										: "rgba(0, 135, 238, 0.05)"
								}
								borderRadius="50%"
							>
								<Text
									color={message?.isSupplier ? "#4BA18A" : "#0087EE"}
									fontWeight="semibold"
								>
									{message?.isSupplier
										? supplierName[0]?.toUpperCase()
										: message?.sender[0]?.toUpperCase()}
								</Text>
							</Flex>
							<Flex
								order={message?.isSupplier ? 1 : 2}
								bg={
									message?.isSupplier
										? "rgba(75, 161, 138, 0.05)"
										: "rgba(0, 135, 238, 0.05)"
								}
								borderRadius=".5rem"
								p=".5rem"
								flexDir="column"
								alignItems={message?.isSupplier ? "flex-end" : "flex-start"}
								maxW="80%"
							>
								<Flex
									alignItems="center"
									justifyContent="space-between"
									width="100%"
									fontSize=".75rem"
									gap="1rem"
								>
									<Text color={message?.isSupplier ? "#4BA18A" : "#0087EE"}>
										{message?.isSupplier ? supplierName : message?.sender}
									</Text>
									<Text color="gray.400">{message.time}</Text>
								</Flex>

								<Text
									textAlign={message.isSupplier ? "right" : "left"}
									mt=".25rem"
								>
									{message.content}
								</Text>
								{message.files?.length > 0 && (
									<Box width="100%">
										<Divider />
										<Flex
											mt=".375rem"
											justifyContent={message?.isSupplier ? "end" : "start"}
											overflowX="auto"
										>
											<SliderFile
												files={message?.files}
												alternativeImg=""
												fileServer={process.env.REACT_APP_CHAT_SERVER}
											/>
										</Flex>
									</Box>
								)}
							</Flex>
						</Flex>
					))}
				</Box>
			) : (
				<Flex
					h="38.5vh"
					alignItems="center"
					justifyContent="center"
					flexDir="column"
					gap=".625rem"
				>
					<Text
						textStyle="primary.secondaryText"
						fontSize="1rem"
						fontWeight={600}
						lineHeight="1.5rem"
					>
						No previous updates found
					</Text>
					<Image src={NoUpdates} height="50%" />
				</Flex>
			)}

			{/* user input section */}
			<Box
				boxShadow="0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04)"
				p=".75rem"
				{...getRootProps()}
			>
				<Flex alignItems="flex-start">
					<Flex
						alignItems="center"
						justifyContent="center"
						height="2rem"
						width="2rem"
						bg="rgba(75, 161, 138, 0.05)"
						borderRadius="50%"
					>
						<Text fontSize="1.40625rem" color="#4BA18A" fontWeight={600}>
							{supplierName[0]?.toUpperCase()}
						</Text>
					</Flex>
					<Textarea
						placeholder="Add a message"
						border="none"
						value={message}
						onChange={(e: any) => setMessage(e.target.value)}
					/>
				</Flex>
				<hr />
				<Flex
					alignItems="center"
					justifyContent="space-between"
					gap="1rem"
					mt=".625rem"
				>
					<Flex width="90%" overflowX="auto">
						<FilesSlider
							files={selectedFiles}
							deleteIcon={true}
							deleteFunction={deleteSelectedFile}
							hideDownloadIcon={true}
						/>
					</Flex>
					<Flex alignItems="center" gap=".75rem" minWidth="10%">
						<input {...getInputProps({})} disabled={sendButtonLoading} />
						<FiPaperclip
							size="1.5rem"
							cursor="pointer"
							onClick={() => {
								if (!sendButtonLoading) {
									open();
								}
							}}
						/>
						<ThemeButton
							children="Send"
							bg="#4BA18A"
							isDisabled={
								message.trim().length < 1 && selectedFiles?.length < 1
							}
							onClick={() => sendMessage()}
							isLoading={sendButtonLoading}
						/>
					</Flex>
				</Flex>
			</Box>
		</Box>
	);
};

export default Chat;
