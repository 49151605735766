import * as React from "react";
import { Flex, Text, Tooltip, Box } from "@chakra-ui/react";
import { TiDelete } from "react-icons/ti";
import { ThumbnailFile } from "./ThumbnailFile";

export interface files {
	files: {
		subtitle: string;
		file: string;
		isPrivateFile?: boolean;
		path: string;
		name: string;
		file_url: string;
		file_name: string;
	}[];
	alternativeImg?: any;
	onClickFunction?: Function;
	deleteIcon?: boolean;
	deleteFunction?: Function;
	acceptedFiles?: any[];
	hideDownloadIcon?: boolean;
	fileServer?: string;
}
export const SliderFile = ({
	files,
	alternativeImg,
	onClickFunction,
	deleteIcon = false,
	deleteFunction,
	acceptedFiles,
	hideDownloadIcon,
	fileServer,
}: files) => {
  
	return (
		<Flex gap=".5rem" overflowX="auto" alignItems="center">
			{files &&
				files.map((each, index) => (
					<Tooltip
						key={index}
						label={
							(each?.subtitle && each?.subtitle) ||
							(each?.file_name && each?.file_name) ||
							(each.name && each?.name)
						}
					>
						<Box>
							{deleteIcon && (
								<Box
									float="right"
									cursor="pointer"
									onClick={() => deleteFunction(each)}
								>
									<TiDelete size="0.9rem" />
								</Box>
							)}
							<Flex
								flexDirection="row"
								flexShrink={0}
								onClick={() =>
									fileServer
										? window.open(fileServer + each.file_url, "_blank")
										: () => {}
								}
								cursor="pointer"
								gap=".5rem"
							>
								<ThumbnailFile
									fileUrl={each.file || each.path || each.file_url}
									alternativeImg={alternativeImg}
									isPrivateFile={each.isPrivateFile}
								/>
								<Box>
									<Text
										textStyle="primary.text"
										fontSize="14px"
										display="flex"
										alignItems="center"
										justifyContent="center"
										gap=".25rem"
									>
										{(each?.subtitle && each?.subtitle.substring(0, 12)) ||
											(each?.file_name && each?.file_name?.substring(0, 12)) ||
											(each.name && each?.name?.substring(0, 12))}
									</Text>
								</Box>
							</Flex>
						</Box>
					</Tooltip>
				))}
		</Flex>
	);
};
